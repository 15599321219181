import React, { useState } from "react";
import AppContext from "./AppContext";

const getToken = () => {
  let token = "";
  try {
    token = localStorage.getItem("finboxToken") || null;
  } catch (error) {
    console.log("Cant find Token", error);
  }
  return token;
};
const getUuid = () => {
  let uuid = "";
  try {
    uuid = localStorage.getItem("userUuid") || null;
  } catch (error) {
    console.log("Cant find uuid", error);
  }
  return uuid;
};
const getQRcode = () => {
  let qrcode = "";
  try {
    qrcode = localStorage.getItem("qrcode") || null;
  } catch (error) {
    console.log("Cant find qr code", error);
  }
  return qrcode;
};
const getRefferal = () => {
  let refferal = "";
  try {
    refferal = localStorage.getItem("refferal") || null;
  } catch (error) {
    console.log("Cant find Refferal code", error);
  }
  return refferal;
};
const getCountry = () => {
  let country = "";
  try {
    country = localStorage.getItem("country") || null;
  } catch (error) {
    console.log("Cant find Country through ip", error);
  }
  return country;
};

export const AppProvider = (props) => {
  const [finboxToken, setToken] = useState(getToken());
  const [uuid, setUuid] = useState(getUuid());
  const [qrcode, setQR] = useState(getQRcode());
  const [refferal, setRefferal] = useState(getRefferal());
  const [country, setCountry] = useState(getCountry());
  const removeToken = () => {
    setToken(null);
    setUuid(null);
    localStorage.removeItem("finboxToken");
    localStorage.removeItem("userUuid");
    localStorage.removeItem("country");
    localStorage.removeItem("refferal");
    localStorage.removeItem("qrcode");
  };
  const updateToken = (val) => {
    localStorage.setItem("finboxToken", val);
    setToken(val);
  };
  const updateUuid = (val) => {
    localStorage.setItem("userUuid", val);
    setUuid(val);
  };
  const updateQR = (val) => {
    localStorage.setItem("qrcode", val);
    setQR(val);
  };
  const updateRefferal = (val) => {
    localStorage.setItem("refferal", val);
    setRefferal(val);
  };
  const updateCountry = (val) => {
    localStorage.setItem("country", val);
    setCountry(val);
  };
  return (
    <AppContext.Provider
      value={{
        finboxToken,
        updateToken,
        uuid,
        updateUuid,
        qrcode,
        updateQR,
        refferal,
        updateRefferal,
        country,
        updateCountry,
        removeToken,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
