import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppProvider from "./context/AppProvider";
import Lottie from "lottie-react";
import Loader from "./assets/images/loaderanimation.json";
const FDP = React.lazy(() => import("./Screens/FDP"));
const SignUp = React.lazy(() => import("./Screens/SignUp"));
const Login = React.lazy(() => import("./Screens/Login"));
const Pos = React.lazy(() => import("./Screens/Position"));
const Standard = React.lazy(() => import("./Screens/Standard"));
const DeviceConnect = React.lazy(() => import("./Screens/DeviceConnect"));
const Bureau = React.lazy(() => import("./Screens/Bureau"));
const BStandard = React.lazy(() => import("./Screens/BureauStandard"));
const Download = React.lazy(() => import("./Screens/Download"));
const Report = React.lazy(() => import("./Screens/Report"));
const Coming = React.lazy(() => import("./Components/Coming"));
const Profile = React.lazy(() => import("./Screens/Profile"));
function App() {
  return (
    <AppProvider>
      <React.Suspense
        fallback={
          <div className="h-screen w-screen flex items-center justify-center">
            <Lottie
              animationData={Loader}
              loop={true}
              className="w-[3rem] absolute top-1/2"
            />
          </div>
        }
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<FDP />} />
          </Routes>
          <Routes>
            <Route exact path="/coming" element={<Coming />} />
          </Routes>
          <Routes>
            <Route exact path="/SignUp" element={<SignUp />} />
          </Routes>
          <Routes>
            <Route exact path="/DeviceConnect" element={<DeviceConnect />} />
          </Routes>
          <Routes>
            <Route exact path="/Bureau" element={<Bureau />} />
          </Routes>
          <Routes>
            <Route exact path="/Login" element={<Login />} />
          </Routes>
          <Routes>
            <Route exact path="/bstandard" element={<BStandard />} />
          </Routes>
          <Routes>
            <Route exact path="/Standard" element={<Standard />} />
          </Routes>
          <Routes>
            <Route exact path="/download" element={<Download />} />
          </Routes>
          <Routes>
            <Route exact path="/report" element={<Report />} />
          </Routes>
          <Routes>
            <Route exact path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </React.Suspense>
    </AppProvider>
  );
}

export default App;
